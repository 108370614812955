import React from "react"
import { graphql } from "gatsby"
import Consumer from "../layouts/Context"
import 'ssr-intersection-observer'
import { Link } from 'react-scroll'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

// Components
import SEO from "../components/seo"
// import IndexWipe from "../components/IndexWipe/IndexWipe"
import Logos from "../components/Logos/Logos"
import ForeWord from "../components/ForeWord/ForeWord"
// import Timeline from "../components/Timeline/Timeline"
import ChapterList from "../components/ChapterList/ChapterList"
import ChapterPopup from "../components/ChapterPopup/ChapterPopup"
import Credits from "../components/Credits/Credits"
import Footer from "../components/Footer/Footer"

// CSS
import "../components/Btn/Btn.scss"

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      menuOpen: false,
      menuBtnVisible: false
    })

    this.countedIn = false
    this.animIn = false
    this.initialised = false
    this.mostRecentIntersect = null
  }

  componentDidMount() {
    setTimeout(this.completeCount, 1200)

    this.context.set({
      accessibility: true
    })
  }

  componentWillUnmount() {
    this.animIn = true
    this.context.set({
      homeInit: true,
      shape: "random",
      yPos: 0
    })

    clearAllBodyScrollLocks()
  }

  completeCount = () => {
    this.countedIn = true
  }

  // Set context state to the new shape
  formShape = () => {
    if (!this.countedIn) return false
    if (this.initialFormTimeout) clearTimeout(this.initialFormTimeout)
  }

  // Set context state to the default random shape
  unformShape = () => {
    if (this.initialFormTimeout) clearTimeout(this.initialFormTimeout)

    this.animIn = false
    this.context.set({
      shape: "random"
    })
  }

  forewordIn = () => {
    if (this.context.homeChapter === 1) return

    this.context.set({
      homeChapter: 1
    })

    this.showMenu()
  }

  forewordOut = () => {
    if (!this.state.menuOpen) return

    this.context.set({
      homeChapter: 2
    })

    this.hideMenu()
  }

  showMenuBtn = () => {
    if (this.state.menuBtnVisible) return

    this.setState({
      menuBtnVisible: true
    })
  }

  hideMenuBtn = () => {
    if (!this.state.menuBtnVisible) return

    this.setState({
      menuBtnVisible: false
    })
  }

  showMenu = () => {
    if (this.state.menuOpen) return
    if (!this.state.menuBtnVisible && window.innerWidth < 768) return

    this.setState({
      menuOpen: true
    })

    if (window.innerWidth < 768) disableBodyScroll()
  }

  hideMenu = () => {
    if (!this.state.menuOpen) return

    this.setState({
      menuOpen: false
    })

    clearAllBodyScrollLocks()
  }

  render() {
    require("../templates/css/accessibility.scss")

    const chapters = this.props.data.chapterData.edges,
          stories = this.props.data.storyData.edges

    return (
      <>
        <SEO
          title="Visions for the Future Internet"
        />

        <div className="home container">
          <div className="home__cols cols flex">

            <div className="home__col home__col--title col m12 tp6">
              <div className="home__content" data-anim="true">
                <svg className="home__svg home__svg--mobile" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 331 226">
                  <path d="M1,225 1,1 330,1 330,225 1,225" strokeWidth="1" stroke="#FFF" fill="none" />
                </svg>
                <svg className="home__svg home__svg--tablet" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 416">
                  <path d="M1,300 1,1 299,1 299,300" strokeWidth="1" stroke="#FFF" fill="none" />
                  <path d="M299,300 299,415 1,415 1,300 299,300" strokeWidth="1" stroke="#FFF" fill="none" />
                </svg>
                <h1>
                  <span>Finding</span>&nbsp;<br />
                  <span>c</span>
                  <span>t</span>
                  <span>r</span>
                  <span>l</span>
                  <span>:</span>
                </h1>
                <p>
                  <span>Visions</span>&nbsp;
                  <span>for</span>&nbsp;
                  <span>the</span>&nbsp;
                  <span>f</span>
                  <span>u</span>
                  <span>t</span>
                  <span>u</span>
                  <span>r</span>
                  <span>e</span>&nbsp;
                  <span>internet</span>
                </p>
                <hr />
                <Link
                  to="chapter-foreword"
                  aria-label="Explore"
                  className="btn btn--home"
                  title="Explore"
                  smooth="easeInOutCubic"
                  duration={1000}
                  tabIndex="0"
                >
                  <div className="btn__content">
                    <span className="btn__inner">
                      <span>
                        <span>E</span>
                        <span>x</span>
                        <span>p</span>
                        <span>l</span>
                        <span>o</span>
                        <span>r</span>
                        <span>e</span>
                      </span>
                      <svg className="btn-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
                        <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M.68.7L16 16"/>
                        <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M16 17V4"/>
                        <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M17 16H4"/>
                        <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M.68.7L16 16"/>
                        <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M16 17V4"/>
                        <path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" d="M17 16H4"/>
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="home__col home__col--logos col m9 tp6 t6 d3">
              <div className="home__logos" data-anim="true">
                <Logos />
              </div>
            </div>

          </div>
        </div>

        <div className="home-content-wrap">

          <Consumer>
            {context =>
              <ChapterPopup visible={this.state.menuBtnVisible} open={this.state.menuOpen} show={this.showMenu} hide={this.hideMenu} context={context} />
            }
          </Consumer>

          <ForeWord {...this.state} showBtn={this.showMenuBtn} hideBtn={this.hideMenuBtn} show={this.forewordIn} hide={this.forewordOut} pageWipe={this.pageWipePanel} />

          {/*<Timeline />*/}

          <ChapterList chapters={chapters} stories={stories} update={this.updateMenu} pageWipe={this.pageWipePanel} access={true} />

          <Credits />
          <Footer />
        </div>
      </>
    )
  }
}

Index.contextType = Consumer

export default Index

export const homeQuery = graphql`
  {
    baseData: allMarkdownRemark(limit: 1) {
      edges {
        node {
          frontmatter {
            title
          }
        }
      }
    }
    chapterData: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: {eq: "chapter"}
        }
      },
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            order
            type
            heading
            title
            shape
            introImage {
              publicURL
            }
            introImageMobile {
              publicURL
            }
          }
        }
      }
    }
    storyData: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: {ne: "chapter"}
        }
      },
      sort: {
        fields: [frontmatter___chapter, frontmatter___order]
        order: ASC
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            order
            type
            title
            chapter
            author
            thumb {
              publicURL
            }
          }
        }
      }
    }
  }
`